import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {HistoryService} from '../services/history.service';
import {tap} from 'rxjs/operators';

/**
 * Guards routes where login is required.
 */
export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const historyService = inject(HistoryService);

  // Only let them continue if we are logged in.
  return authService.checkAuthenticationState().pipe(
    tap(userLoggedIn => {
      // If they are not logged in, send them to the login page.
      if (!userLoggedIn) {
        historyService.setLastVisitedRoute(state.url);
        router.navigateByUrl('/auth/login');
      }
    }),
  );
};
